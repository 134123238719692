import React from "react";
import Layout from "../components/layout-home";
import styles from "../styles/index.module.css";
import {Link} from "gatsby";

export default () => (
    <Layout>
        <div className={styles.centered_container}>
            <div className={styles.centered_item}>
                <div className={styles.row_link_centered}>
                    <Link to={'/fanzine'} className={styles.ball_link}>
                        <img
                            className={styles.img_link}
                            src="/images/home/logo_zine.png"
                            alt="'zine'"
                            style={{width: '80%'}}
                        />
                    </Link>
                </div>

                <div className={styles.row_link_space_top}>
                    <Link to="/crew" className={styles.ball_link}>
                        <img
                            className={styles.img_link}
                            src="/images/home/logo_crew.png"
                            style={{width: '95%'}}
                            alt="crew"
                        />
                    </Link>
                    <a
                      href="https://codafanzine.bandcamp.com/music"
                      target="_blank" rel="noreferrer"
                      className={styles.ball_link}>
                        <img
                          className={styles.img_link}
                          src="/images/home/logo_music.png"
                          alt="music"
                        />
                    </a>

                </div>
                <a href="https://codafanzine.bandcamp.com/album/senza-prospettiva"
                   target="_blank" rel="noreferrer">
                    <div className={styles.img_home}/>
                </a>
                <div className={styles.row_link_space_bottom}>
                    <Link
                      to="/distro"
                      className={styles.ball_link}>
                        <img
                          className={styles.img_link}
                          src="/images/home/logo_distro.png"
                          alt="music"
                        />
                    </Link>
                    <Link to="/events" className={styles.ball_link}>
                        <img
                          className={styles.img_link}
                          src="/images/home/logo_party.png"
                          alt="'events"
                        />
                    </Link>
                </div>
                <div className={styles.row_link_centered}>
                    <Link to="/about" className={styles.ball_link}>
                        <img
                          className={styles.img_link}
                          src="/images/home/logo_about.png"
                          alt="about"
                        />
                    </Link>
                </div>
            </div>
        </div>
    </Layout>
);
