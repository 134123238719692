import React, { useRef, useState } from "react";
import styled from "styled-components";
import Header from "../components/header";


const Footer = styled.footer`
  margin-bottom: 1.5rem;
  text-align: center;
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
  font-size: calc(0.5rem + 1vmin);
  
  a:hover {
    color: #39df00;
  }
`;
const MailinglistButton = styled.a`
  font-family: 'SitkaSmall',serif;
  border: 0;
  cursor: pointer;
  
  &:hover {
    text-decoration: underline;
    color: #39df00;
  }
`;
const EmailForm = styled.div`
  display: flex;
  gap: .5rem;
  align-items: center;
  
  input {
    border: 0;
    font-size: 1em;
    line-height: 1.5em;
    font-family: arial;
    vertical-align: middle;
  }
  
  .button {
    font-family: 'DS-DIGI',serif;
    font-size: 1.4em;
    cursor: pointer;
    color: #39df00;
  }
`

const Component = ({ children }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const isValidEmail = (email) => !!/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(email);
  const addEmail = () => {
    const email = inputRef.current.value;
    console.log('EMAIL: ', email);
    if (isValidEmail(email)) {
      fetch('https://us-central1-madcapcollective.cloudfunctions.net/mailer', {
        method: 'POST',
        body: JSON.stringify({ newEmail: email }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      alert(`Thanks! Your email is now part of our mailing list`);
    } else {
      alert(`Email is invalid`);
    }
    setEditing(false)
  }
  const edit = () => {
    setEditing(true);
    setTimeout(() => inputRef.current.focus(), 100);
  }

  return (
    <>
      <Header />
      <div className="site">
        <div className="site-content">{children}</div>
        <Footer>
          <a href="mailto:info@codafanzine.com">
            info@codafanzine.net
          </a>
          <div>
            {!editing && <MailinglistButton onClick={edit}>Mailing list</MailinglistButton>}
            {editing && (
              <EmailForm>
                <input type="email" ref={inputRef} placeholder={`insert your email`} />
                <a className={'button'} onClick={addEmail}>Add me</a>
              </EmailForm>
            )}
          </div>
        </Footer>
      </div>
    </>
  );
};
export default Component;
